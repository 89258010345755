import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { StaticImage } from "gatsby-plugin-image";

import { Br, Container, Text } from "components";

const BuildExperience = () => {
  return (
    <section className={buildExperienceWrapperStyle}>
      <Container>
        <Text value="Make Your Mark at Parkway" variant="h2" className="mb-3" />
        <Text
          value={
            <>
              Explore testimonials from our outstanding team members dedicated
              to supporting the <Br on="desktop" />
              company through passion and relentless drive to innovate and
              excel.
            </>
          }
          variant="p18"
        />

        <div className={testimonialStyle}>
          {testimonials.map((testimony, index) => {
            return (
              <div className={testimonyStyle} key={`testimony_${index}`}>
                {testimony.image}
                <Text
                  variant="h4"
                  value={testimony.name}
                  className="mb-1 mt-4"
                />
                <Text
                  variant="p14"
                  value={testimony.title}
                  className="mb-2 font-bold"
                />
                <Text variant="p16" value={testimony.text} />
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

const buildExperienceWrapperStyle = ctl(`
md:py-[90px]
py-[61px]
bg-secondary
text-secondary-100
`);

const testimonialStyle = ctl(`
grid
lg:grid-cols-2
gap-8
md:mt-[54px]
mt-[44px]
`);

const testimonyStyle = ctl(`
bg-secondary-1000
rounded-xl
px-[28px]
pt-[42px]
pb-[31px]
`);
const testimonyImageStyle = ctl(`
max-w-[71px]
`);

const testimonials = [
  {
    image: (
      <StaticImage
        src="../../../assets/images/pngs/careers/Ayomide-Kassim.png"
        alt="Ayomide Kassim"
        className={testimonyImageStyle}
      />
    ),
    name: "Ayomide Kassim",
    title: "Product Department",
    text: "Parkway instills a sense of great possibility, making you believe that “nothing is beyond your reach.” The open and honest environment encourages you to truly be yourself without feeling stressed so that you can produce your best work. Joining Parkway has been extraordinary, and I’ve loved being part of such an inspiring community.",
  },
  {
    image: (
      <StaticImage
        src="../../../assets/images/jpegs/careers/oladayo.png"
        alt="Oladayo Olukowi"
        className={testimonyImageStyle}
      />
    ),
    name: "Oladayo Olukowi",
    title: "Product Design Department",
    text: "At Parkway, it’s not just about your job; you also grow as a person. The system here encourages responsibility which stays with you even outside of work. The most interesting part of being in Parkway is the positive atmosphere and the feeling that my contributions truly matter.",
  },
  {
    image: (
      <StaticImage
        src="../../../assets/images/pngs/careers/Lucy-Akande.png"
        alt="Lucy Akande"
        className={testimonyImageStyle}
      />
    ),
    name: "Lucy Akande",
    title: "Reconciliation & Settlement Department",
    text: "For me, each day at the PARK serves as a profound learning experience, highlighting the importance of financial integrity. The environment at Parkway is nothing short of transformative and so far, I've been guided by inspiring team leads, and fellow team members who have helped my journey here.",
  },
  {
    image: (
      <StaticImage
        src="../../../assets/images/pngs/careers/etim-amanda.png"
        alt="Etim Esther Amanda"
        className={testimonyImageStyle}
      />
    ),
    name: "Etim Esther Amanda",
    title: "Audit & Compliance Department",
    text: "One of the most significant aspects of my journey at Parkway has been interacting with individuals who possess a wealth of experience and expertise which has been incredibly enriching for me. Their insights, mentorship, and willingness to share knowledge have played a big role in shaping my understanding of the organization and refining my professional skills. There has never been a time where I do not enjoy doing my job and that’s safe to say “It’s really how Parkway makes you feel.” - at home.",
  },
];

export { BuildExperience };
