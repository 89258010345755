import * as React from "react";

import { Layout } from "components";
import {
  BuildExperience,
  CareersPageHeader,
  Perks,
  Vibrant,
  Roles,
  GraduateInternship,
  BecomeAPart,
} from "templates/careers";

const WhoWeArePage = () => (
  <Layout title="Careers" theme="light">
    <CareersPageHeader />
    <BuildExperience />
    <Perks />
    <Vibrant />
    <Roles />
    <GraduateInternship />
    <BecomeAPart />
  </Layout>
);

export default WhoWeArePage;
