import React from "react";
// import ctl from "@netlify/classnames-template-literals";
import { StaticImage } from "gatsby-plugin-image";
import { Br, Container, Text, Button } from "components";

const GraduateInternship = () => {
  return (
    <section className="mt-[100px] pb-[50px]">
      <Container>
        <div className="flex max-[768px]:flex-col items-center bg-[#FBFBFB] p-[30px]">
          <StaticImage src="../../../assets/Images/jpegs/careers/parkway-people.jpg" />

          <div className="p-[40px]">
            <Text value={<>Graduate Internship Program</>} variant="h3" />

            <Text
              className="mt-[10px] mb-[30px]"
              value={
                <>
                  Are you a fresh graduate, smart, and looking to do great work?
                  We have a <Br on="desktop" />
                  space for you!
                </>
              }
              variant="p16"
            />

            <Button hasArrow text="Apply now" size="small" to="#" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export { GraduateInternship };
