import { Br, Container, Text } from "components";
import React from "react";
import ctl from "@netlify/classnames-template-literals";
import HybridWorkIcon from "svgs/hybrid-work.svg";
import MentorshipIcon from "svgs/mentorship.svg";
import LearningAndDevelopmentIcon from "svgs/learning-and-development.svg";
import LeaveAllowanceIcon from "svgs/leave-allowance.svg";
import HealthInsuranceIcon from "svgs/health-insurance.svg";
import CareerDevelopmentIcon from "svgs/career-development.svg";
import PensionIcon from "svgs/pension.svg";
import GroupInsuranceIcon from "svgs/group-insurance.svg";
import InternshipIcon from "svgs/internship.svg";

const Perks = () => {
  return (
    <section className={perksWrapperStyle}>
      <Container>
        <Text
          value={
            <>
              You’re not just building, a world <Br on="desktop" />
              of perks awaits you!
            </>
          }
          variant="h2"
        />
        <Text
          value={<>In addition to the competitive package, we also offer;</>}
          variant="p16"
          className="mt-2"
        />

        <div className={perksListStyle}>
          {perksList.map((perk, index) => {
            return (
              <div
                style={{ background: perk.background }}
                className={perkCard}
                key={`perks_${index}`}
              >
                {perk.icon}
                <Text variant="p18" value={perk.title} className="font-black" />
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

const perksWrapperStyle = ctl(`
md:py-[100px]
py-[61px]
text-center
`);

const perksListStyle = ctl(`
grid
md:grid-cols-3
gap-[64px]
md:mt-[113px]
mt-[44px]
`);

const perkIconStyle = ctl(`
mx-auto
h-[56px]
mb-4
`);

const perkCard = ctl(`
  rounded-[20px]
  py-[50px]
`);

const perksList = [
  {
    icon: <HybridWorkIcon className={perkIconStyle} />,
    title: "Hybrid work system",
    background: "#FAF1D2",
  },
  {
    icon: <MentorshipIcon className={perkIconStyle} />,
    title: "Comprehensive mentorship",
    background: "#FBFBFB",
  },
  {
    icon: <LearningAndDevelopmentIcon className={perkIconStyle} />,
    title: "Learning and development",
    background: "#FEEAE1",
  },
  {
    icon: <LeaveAllowanceIcon className={perkIconStyle} />,
    title: "Paid leave allowance",
    background: "#FBFBFB",
  },
  {
    icon: <HealthInsuranceIcon className={perkIconStyle} />,
    title: "Health Insurance Plan",
    background: "#FEEAE1",
  },
  {
    icon: <CareerDevelopmentIcon className={perkIconStyle} />,
    title: "Career development",
    background: "#FAF1D2",
  },
  {
    icon: <PensionIcon className={perkIconStyle} />,
    title: "Pension",
    background: "#FAF1D2",
  },
  {
    icon: <GroupInsuranceIcon className={perkIconStyle} />,
    title: "Group Life Insurance",
    background: "#FEEAE1",
  },
  {
    icon: <InternshipIcon className={perkIconStyle} />,
    title: "Graduate Internship",
    background: "#FBFBFB",
  },
];

export { Perks };
