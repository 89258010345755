import { Container, Text, Button } from "components";
import React from "react";
import ctl from "@netlify/classnames-template-literals";

const Roles = () => {
  return (
    <section id="opening" className="pt-[100px]">
      <Container>
        <div className="text-center">
          <Text value={<>Our Available Roles</>} variant="h2" />
          <Text
            value={<>We know you have what it takes. Join Us!</>}
            variant="p18"
          />
        </div>

        <div className={jobsListContainer}>
          {jobList.map((job, index) => {
            return (
              <div key={`joblist_${index}`} className={jobCard}>
                <div>
                  <Text
                    className={jobTitle}
                    value={<>{job.title}</>}
                    variant="h4"
                  />
                  <p className={jobType}>{job.location}</p>
                  <div className={category}>
                    <span className={subJob}>{job.seniority}</span>
                    <span className={subJob}>{job.range}</span>
                    <span className={subJob}>{job.experience}</span>
                  </div>

                  <ul className="mt-[20px]">
                    {job.descriptions.map((item, index) => {
                      return (
                        <li key={`list_${index}`} className={descList}>
                          {item}
                        </li>
                      );
                    })}
                  </ul>

                  <div className="mt-[20px]">
                    <span className={closingDate}>{job.closingDate}</span>
                  </div>
                </div>
                <Button text="Apply" size="small" to={job.applicationLink} />
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

const jobsListContainer = ctl(`
  text-left
  py-8
  mx-auto
`);

const jobCard = ctl(`
  mt-8
  p-6
  flex
  lg:flex-row
  flex-col
  justify-between
  lg:items-center
  gap-y-4
  border-2
  border-[#DDDDDD]
  rounded-[8px]
`);

const jobTitle = ctl(`
  font-bold
`);

const jobType = ctl(`
  text-[#868686]
  text-[12px]
  mb-[12px]
  font-medium
`);

const category = ctl(`
  flex 
  gap-[10px] 
  flex-wrap
`);

const subJob = ctl(`
 bg-[#EFEFEF] 
 text-[12px]
 rounded-[100px]
 py-[5px] 
 px-[10px] 
 font-bold
`);

const descList = ctl(`
  list-disc 
  text-[#444444] 
  mt-[5px] 
  ml-[20px]
`);

const closingDate = ctl(`
 bg-[#FCF8E8]
 rounded-[100px]
 py-[5px] 
 px-[10px] 
 text-[12px]
 font-bold
`);

const jobList = [
  {
    title: "Product Designer",
    workType: "Hybrid",
    location: "Lagos, Nigeria",
    seniority: "Mid-Senior",
    range: "Full-Time",
    experience: "1 - 3 Years Experience",
    descriptions: [
      "Have a first degree in Computer Science, and/or related discipline.",
      "Have completed NYSC.",
      "Have strong communication and interpersonal skills; and an ability to work in a team.",
      "All applicants would be subjected to interview/assessments.",
    ],
    closingDate: "Closes on: 28 November, 2024",
    applicationLink: "#",
  },
  {
    title: "Product Manager",
    workType: "Hybrid",
    location: "Lagos, Nigeria",
    seniority: "Mid-Senior",
    range: "Full-Time",
    experience: "1 - 3 Years Experience",
    descriptions: [
      "Have a first degree in Computer Science, and/or related discipline.",
      "Have completed NYSC.",
      "Have strong communication and interpersonal skills; and an ability to work in a team.",
      "All applicants would be subjected to interview/assessments.",
    ],
    closingDate: "Closes on: 28 November, 2024",
    applicationLink: "#",
  },
];

export { Roles };
