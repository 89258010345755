import React from "react";
import { Text, Container } from "components";
import { Steps } from "./steps";

const BecomeAPart = () => {
  return (
    <Container>
      <div className="pb-[100px]">
        <Text
          value={<>How To Become a Part of Our Team</>}
          variant="h2"
          className="mt-[100px] mb-[5px]"
        />

        <Text
          value={
            <>
              Note that you will have to go through each of the following
              processes:
            </>
          }
          variant="p16"
          className="mb-[30px]"
        />

        <Steps />
      </div>
    </Container>
  );
};

export { BecomeAPart };
