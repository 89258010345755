import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { StepsToBecome } from "./steps-to-become";

const Steps = () => {
  return (
    <>
      {ourStoryData.map((story, index) => {
        const isLast = index === ourStoryData.length - 1;

        return <StepsToBecome key={story.heading} isLast={isLast} {...story} />;
      })}
    </>
  );
};

const ourStoryData = [
  {
    heading: "Apply",
    description: (
      <>
        <p className="mb-6">
          You can apply for an open position through any job board or advert
          placed by the company. Simply submit your resume/CV and any additional
          document that may be requested in the job advert.{" "}
        </p>
      </>
    ),
    image: (
      <StaticImage alt="Apply" src="../../../assets/images/svgs/apply.svg" />
    ),
  },
  {
    heading: "Technical Assessment",
    description:
      "Once your application is reviewed successfully, you will be invited to take part in a technical assessment for the designated role. This assessment is to test your skills and expertise relevant to the position.",
    image: (
      <StaticImage
        alt="Technical Assessment"
        src="../../../assets/images/svgs/techincal-assessment.svg"
      />
    ),
  },
  {
    heading: "Panel Interview",
    description:
      "Once you pass the technical assessment, you will proceed to the next stage, a panel interview, where you will discuss the role and benefits with a team of specialists face-to-face.",
    image: (
      <StaticImage
        alt="Panel Interview"
        src="../../../assets/images/svgs/panel.svg"
      />
    ),
  },
  {
    heading: "Selection and Decision",
    description:
      "Finally, the hiring team deliberates and selects a candidate from the list of finalist. Factors considered may include performance in the technical assessment, alignment with the company’s value, and the candidate’s ability to contribute to long-term goals.",
    image: (
      <StaticImage
        alt="selection and decision"
        src="../../../assets/images/svgs/selection.svg"
      />
    ),
  },
];

export { Steps };
