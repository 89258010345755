import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { StaticImage } from "gatsby-plugin-image";
import { Br, PageHeader } from "components";

const CareersPageHeader = () => {
  return (
    <>
      <PageHeader
        title={
          <>
            We Work, We Play, <Br on="desktop" />
            We Make Things Happen
          </>
        }
        subheading={
          <>
            The future of financial and banking services in Africa is digital,{" "}
            <Br on="desktop" /> and we want you to join us to build it.
          </>
        }
        center
        button={{ text: "Build with Parkway", to: "/careers#opening" }}
        theme="light"
      />
      <div className={glide}>
        <div className={glideWrapper}>
          {parkwayImages.concat(parkwayImages).map((parkwayImage, index) => {
            return (
              <div className={glideImage} key={`pkcareer_${index}`}>
                {parkwayImage.image}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const parkwayImages = [
  {
    image: (
      <StaticImage
        src="../../../assets/images/pngs/careers/Chuks-Parkway-em.png"
        alt="Chuks-Parkway-em"
      />
    ),
  },
  {
    image: (
      <StaticImage
        src="../../../assets/images/pngs/careers/staff-at-parkway.png"
        alt="staff-at-parkway"
      />
    ),
  },
  {
    image: (
      <StaticImage
        src="../../../assets/images/pngs/careers/hangout-staff.png"
        alt="hangout-staff"
      />
    ),
  },
  {
    image: (
      <StaticImage
        src="../../../assets/images/pngs/careers/hbd-celebrations-parkway.png"
        alt="hbd-celebrations-parkway"
      />
    ),
  },
  {
    image: (
      <StaticImage
        src="../../../assets/images/pngs/careers/ceo-parkway.png"
        alt="ceo-parkway"
      />
    ),
  },
  {
    image: (
      <StaticImage
        src="../../../assets/images/pngs/careers/parkway-rebrand.png"
        alt="parkway-rebrand"
      />
    ),
  },
  {
    image: (
      <StaticImage
        src="../../../assets/images/pngs/careers/hbd-celebrations-parkway.png"
        alt="hbd-celebrations-parkway"
      />
    ),
  },
  {
    image: (
      <StaticImage
        src="../../../assets/images/pngs/careers/staff-at-parkway.png"
        alt="staff-at-parkway"
      />
    ),
  },
];

const glide = ctl(`
w-full
overflow-hidden  
mt-[-200px]
mb-[200px]
`);

const glideWrapper = ctl(`
flex
gap-[20px]
animate-scroll
`);

const glideImage = ctl(`
flex-none 
w-1/10 
nth-child-even:mt-[100px]  
`);

export { CareersPageHeader };
