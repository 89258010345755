import { Container, Text, Button } from "components";
import React from "react";
import ctl from "@netlify/classnames-template-literals";

const Jobs = () => {
  return (
    <section id="opening" className={jobsWrapperStyle}>
      <Container>
        <Text value={<>Job openings</>} variant="h2" />
        <Text
          value={<>Ready to kickstart your journey with Parkway? Join us!</>}
          variant="p18"
        />

        <div className={jobsListContainer}>
          {jobList.map((job, index) => {
            return (
              <div key={index} className={jobCard}>
                <div className={textLeft}>
                  <Text value={<>{job.title}</>} variant="h4" />
                  <Text value={<>{job.excerpt}</>} variant="p" />
                </div>
                <Button
                  text="Apply now"
                  size="small"
                  to={job.applicationLink}
                />
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

const jobsWrapperStyle = ctl(`
md:py-[90px]
py-[61px]
text-center
`);

const jobsListContainer = ctl(`
text-left
py-8
max-w-[1000px]
mx-auto
shadow
`);

const jobCard = ctl(`
mt-8
p-4
flex
lg:flex-row
flex-col
justify-between
lg:items-center
gap-y-4
`);

const textLeft = ctl(`
text-left
lg:max-w-[600px]
`);

const jobList = [
  {
    title: "Graduate Internship Program",
    excerpt:
      "We are looking for young, smart graduates with the ability to work in a team to fill some roles",
    applicationLink: "/careers/graduate-internship-program",
  },
];

export { Jobs };
