import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { StaticImage } from "gatsby-plugin-image";
import { Br, Container, Text } from "components";

const Vibrant = () => {
  return (
    <section className={vibrantTeam}>
      <Container>
        <div className="text-center">
          <Text value={<>Join Our Vibrant Team</>} variant="h2" />

          <Text
            value={
              <>
                We understand the importance of maintaining a work life{" "}
                <Br on="desktop" />
                balance.
              </>
            }
            variant="p16"
          />
        </div>

        <div className="mt-[50px]">
          <div className={galleryRow}>
            <StaticImage
              src="../../../assets/images/pngs/careers/parkway-staff-1.png"
              alt="parkway-staff"
            />
            <StaticImage
              src="../../../assets/images/pngs/careers/IWD-parkway-gallery.png"
              alt="IWD-parkway-gallery"
            />
          </div>

          <div className={galleryRow}>
            <StaticImage
              src="../../../assets/images/pngs/careers/parkway-birthday-celebrants.png"
              alt="parkway-birthday-celebrants"
            />

            <StaticImage
              src="../../../assets/images/pngs/careers/parkway-safety-training.png"
              alt="parkway-safety-training"
            />

            <StaticImage
              src="../../../assets/images/pngs/careers/parkway-staff-exercising.png"
              alt="parkway-staff-exercising"
            />
          </div>

          <div className={galleryRow}>
            <StaticImage
              src="../../../assets/images/pngs/careers/parkway-outing.png"
              alt="parkway-outing"
            />
            <StaticImage
              src="../../../assets/images/pngs/careers/parkway-seminar.png"
              alt="parkway-seminar"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

const vibrantTeam = ctl(`
  my-[100px]
`);

const galleryRow = ctl(`
  flex 
  gap-[15px]
  mt-[15px]
  max-[600px]:flex-col
`);

export { Vibrant };
